
import React from "react"
import { graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styles from "./blog-archives.module.scss"
import BlogPostList from '../components/blog_post_list.js'

export const blogArchiveQuery = graphql`
  query blogArchiveQuery($skip: Int!, $limit: Int!) {
    allMdx(
      filter: {
        frontmatter: { layout: {eq: "post"}, published: { eq: true } }
      }
      sort: { fields: frontmatter___date, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      totalCount
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
            categories
            date(
              formatString: "LL"
              locale: "nb-NO"
            )
          }
          fields {
            slug
          }
        }
      }
    }
  }
`

export default class BlogArchives extends React.Component {
  render() {
    const posts = this.props.data.allMdx.edges
    const { currentPage, numPages } = this.props.pageContext
    return(
      <Layout>
        <SEO title={ `Bloggarkiv ${currentPage}/${numPages}` }/>
        <h1>Bloggarkivet: side { currentPage } av { numPages }</h1>
        <BlogPostList posts={ posts } />
        <nav className={ styles.paginationLinks }>
          {Array.from({ length: numPages }, (_, i) => {
            if (currentPage === i + 1) {
              return(`${i + 1}`)
            } else {
              let url=`/blogg/${i===0 ? "" : i + 1}`
              let key=`pagination-number${i + 1}`
              return(<AniLink fade key={ key } to={ url }>{i + 1}</AniLink>)
            }
          })}
        </nav>
      </Layout>
    )
  }
}
